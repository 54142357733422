var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar navbar-expand-lg"},[_c('div',{staticClass:"container"},[_c('router-link',{staticClass:"logo",attrs:{"to":"#","title":"PSD to CMS"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(require('./../assets/images/logo-light.png')),expression:"require('./../assets/images/logo-light.png')"}],attrs:{"alt":"logo"}})]),_vm._m(0),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav ml-auto"},[_c('li',{staticClass:"nav-item"},[_c('router-link',{staticClass:"nav-link active",attrs:{"to":"#","title":"Home"}},[_vm._v("Home")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#services',
              duration: 1000,
              easing: 'linear'
            }),expression:"{\n              el: '#services',\n              duration: 1000,\n              easing: 'linear'\n            }"}],staticClass:"nav-link",attrs:{"to":"#services","title":"Services"}},[_vm._v("Services")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({ el: '#about', duration: 1000, easing: 'linear' }),expression:"{ el: '#about', duration: 1000, easing: 'linear' }"}],staticClass:"nav-link",attrs:{"to":"#about","title":"About"}},[_vm._v("About")])],1),_c('li',{staticClass:"nav-item"},[_c('router-link',{directives:[{name:"scroll-to",rawName:"v-scroll-to",value:({
              el: '#contact',
              duration: 1000,
              easing: 'linear'
            }),expression:"{\n              el: '#contact',\n              duration: 1000,\n              easing: 'linear'\n            }"}],staticClass:"nav-link",attrs:{"to":"#contact","title":"Contact"}},[_vm._v("Contact")])],1)])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-toggle":"collapse","data-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('span',{staticClass:"icon-bar"},[_c('i',{staticClass:"fas fa-bars"})])])}]

export { render, staticRenderFns }